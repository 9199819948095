import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  data = true;

  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang(localStorage.getItem('lang') || 'en');
  }

  ngOnInit() {
    // localStorage.setItem('lang', localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en');
  }
}
