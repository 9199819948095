<div class="snack-container {{getIcon.type}}-toast">
  <div class="snack-container-message">
    <div class="d-flex align-items-center justify-content-between w-100">
      <div class="d-flex align-items-center">
        <mat-icon *ngIf="data.type == 'success'" class="icon-size">check_circle</mat-icon>
        <mat-icon *ngIf="data.type == 'error'" class="icon-size">warning</mat-icon>
        <span class="ml-3">{{data.message | translate}}</span>
      </div>
      <div *ngIf="data?.action" class="d-flex align-items-center">
        <mat-icon class="icon-size cursor-pointer" (click)="close()">close</mat-icon>
      </div>
    </div>
  </div>
</div>
