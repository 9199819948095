import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  constructor(private router: Router) {}

  canLoad() {
    if (localStorage.getItem('ifUserLoggedin')) {
      return this.isVerified();
    }

    this.router.navigate(['/login']);
    return false;
  }

  isVerified() {
    let user = JSON.parse(localStorage.getItem('ROUserData'));
    // console.log(user)
    if (user) {
      // if (user.status) {
      return true;
      // } else {
      //   this.router.navigate(['/login']);
      //   return false;
      // }
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
