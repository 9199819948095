import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/core/component/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(
    public MatSnackBar: MatSnackBar
  ) {}

  // Method to toast success/error messages (custom)
  customSnackBar(message: string, type: string, ) {
    this.MatSnackBar.openFromComponent(SnackBarComponent, {
      data: {
        message,
        type: type,
      },
      duration: 3000,
    });
  }

  customSnackBarWithoutTimer(message: string, type: string, action: string) {
    this.MatSnackBar.openFromComponent(SnackBarComponent, {
      data: {
        message,
        type: type,
        action
      }
    });
  }
}
