import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { authenticationRoutes } from './shared/shared-components/reusable-codes/common-variables';
import { AuthGuard } from './authentication/services/guard/auth.guard';

const routes: Routes = [
  ...authenticationRoutes,
  {
    path: '',
    loadChildren: () =>
      import('./features/features.module').then((m) => m.FeaturesModule),
    canLoad: [AuthGuard],
  },
  // {
  //   path: '', loadChildren: () => import('./authentication/authentication.module').then( m => m.AuthenticationModule ),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
