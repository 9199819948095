import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanLoad,
  Route,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IsLoggedIn implements CanLoad {
  constructor(private router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]) {
    if (localStorage.getItem('ifUserLoggedin')) {
      let params = [];
      // console.log('Query param ', next.queryParams);
      // check if it's a magic link
      // .token && params.salt && params.user
      if (params) {
        return true;
      } else {
        return this.isVerified();
      }
    } else {
      return true;
    }
  }

  isVerified() {
    let user = JSON.parse(localStorage.getItem('adminData'));
    if (user.email_verified) {
      if (user.status > 1) {
        this.router.navigate(['/']);
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
