import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public projectName = environment.projectName;
  $baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private router: Router) { }

  // USER
  getEmailAvailability(email) {
    let url = '/users/email/availability/?email=' + email;
    return this.getData(url);
  }

  getUnameAvailability(uname) {
    let url = '/users/username/availability/?username=' + uname;
    return this.getData(url);
  }

  getPswdAvailability(pswd) {
    let url = '/users/password/validity/?password=' + pswd;
    return this.getData(url);
  }

  memberSignup(data) {
    let url = '/users/member/signup/';
    return this.postData(url, data);
  }

  validateEmail(data) {
    let url = '/users/email/verify/';
    return this.postData(url, data);
  }

  validatePassword(data) {
    let url = '/auth/password/check/';
    return this.postData(url, data);
  }

  forgotPswd(email) {
    let data = {
      email,
      tenant: localStorage.getItem('tenantId'),
    };
    let url = '/auth/password/reset/';
    return this.postData(url, data);
  }

  login(data) {
    let url = '/auth/login/';
    return this.postData(url, data);
  }

  validateDomain(data) {
    let url = '/tenants/domain/validate/';
    return this.postData(url, data);
  }

  getDashboardTheme(id) {
    let url = `/dashboard/tenant/${id}/theme/`;
    return this.getData(url);
  }

  verifyResetToken(data) {
    let url = '/auth/validate/';
    return this.postData(url, data);
  }

  resetUserPassword(data) {
    let url = '/auth/password/reset/confirm/';
    return this.postData(url, data);
  }

  changePassword(data) {
    let url = '/auth/password/change/';
    return this.postData(url, data);
  }

  getPrivacyPolicy() {
    let url = `/accounts/privacy-policy/`;
    return this.getData(url);
  }

  logout() {
    localStorage.removeItem('ifUserLoggedin');
    localStorage.removeItem('ROUserData');
    localStorage.removeItem('nodeID');
    localStorage.removeItem('tenantId');
    localStorage.removeItem('tenantConfigurations');
    localStorage.removeItem('supplyChainId');
    localStorage.removeItem('supplyChainName');
    localStorage.removeItem('theme');
    // console.log('User Logged Out!');
    this.router.navigateByUrl('login');
  }

  // primary methods
  getData(url: any) {
    let baseUrl = this.$baseUrl + url;
    return this.http.get(baseUrl, this.Options());
  }

  getDataByParams(url: any, params) {
    let baseUrl = this.$baseUrl + url;
    return this.http.get(baseUrl, this.Options3(params));
  }

  postData(url: any, data: any) {
    let baseUrl = this.$baseUrl + url;
    return this.http.post(baseUrl, data, this.Options());
  }

  patchData(url: any, data: any) {
    let baseUrl = this.$baseUrl + url;
    return this.http.patch(baseUrl, data, this.Options());
  }

  // httpOptions
  Options() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        language: localStorage.getItem('lang') || 'en',
        'Accept-Language': localStorage.getItem('lang') || 'en',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
    };
    return httpOptions;
  }

  Options3(params) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        language: localStorage.getItem('lang') || 'en',
        'Accept-Language': localStorage.getItem('lang') || 'en',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      params: new HttpParams({ fromObject: params }),
    };
    return httpOptions;
  }

  getIPAddress() {
    return this.http.get<any>('https://geolocation-db.com/json/');
    // .pipe( catchError(err => {
    //     return throwError(err);
    //   }),
    //   tap(response => {
    //     console.log(response.IPv4);
    //   }) )
  }

  getPrivacyPolicyHtml() {
    return this.http.get('assets/privacy-policy/privacy-policy.html', { responseType: 'text' })
  }
}
