import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent implements OnInit {
  type = 1;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
  private _snackRef: MatSnackBarRef<SnackBarComponent>) {
    // console.log(data);
  }

  ngOnInit() {}

  get getIcon() {
    switch (this.data.type) {
      case 'success':
        return {type: this.data.type, icon: 'check'};
      case 'error':
        return {type: this.data.type, icon: 'faults'};
      case 'warn':
        return {type: this.data.type, icon: 'warning_outline'};
      case 'info':
        return {type: this.data.type, icon: 'info'};
    }
  }

  close(){
    this._snackRef.dismiss()
  }
}
