import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { saveAs } from 'file-saver';

import { ResponseArray } from '../../../core/component/models/response.interface';
import { Country } from '../../../core/component/models/country.interface';
import { ConnectionNode } from '../../../core/component/models/node.interface';
import { Product } from '../../../core/component/models/product.interface';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  dateFormat = environment.dateFormat;
  $baseUrl = environment.baseUrl;

  dateFormatWithTime = 'dd/MM/yyyy, h:mm a';

  public RoUserDetails: any;

  public companyData = new BehaviorSubject(null);
  public entityDetails = new BehaviorSubject(null);
  entityNotifications = [];

  countryData: any;
  public verificationsCount = new BehaviorSubject(null);
  notificationObject;

  public sideBarData = new BehaviorSubject(null);
  supplyChainData = new Subject<any>();
  hideSupplyChain = new Subject<any>();
  companyProfileUpdated = new Subject<any>();
  updateSupplyChainDropdown = new Subject<any>();
  claimCreated = new Subject<any>();
  bulkUploadExcelValidated = new Subject<any>();
  bulkUploadTypeChanged = new Subject<any>();
  userDetailsUpdated = new Subject<any>();
  languageChanged = new Subject<any>();

  public dynamicFormSubmitted = new Subject<any>();
  public newPolicyUpdateReceived = new Subject<any>();

  emailValidators =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // emailValidators =
  // /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
  // /^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$/;
  quantityRegex_fr = /^\d{1,3}( ?\d{3})*(\,\d{1,2})?$/;
  // /^[0-9 ]{1,15}([,][0-9]{1,2})?$/;
  quantityRegex = /^\d{1,3}(,?\d{3})*(\.\d{1,2})?$/;
  // /^[0-9,]{1,15}([.][0-9]{1,2})?$/;

  phoneNumberRegex = '^[0-9]+';
  phoneNumberRegex2 = '^[+]?[0-9]+';
  phoneNumberRegex3 =
    /^\+?\(?([0-9]{2,4})([)\s.-]+)?([0-9]{3,4})([\s.-]+([0-9]{3,4}))?$/;

  constructor(public http: HttpClient) {}

  customNumberPipe(value, precision = 0) {
    const lang = localStorage.getItem('lang') || 'en';
    let roundedNumber = +Number(value).toFixed(precision);
    if (value) {
      if (lang == 'en') {
        return roundedNumber.toLocaleString('en-EN');
      } else if (lang == 'fr') {
        // console.log(roundedNumber.toLocaleString('fr-FR'))
        return roundedNumber.toLocaleString('fr-FR');
      }
    } else {
      return 0;
    }
  }

  converToRealNumber(number: any) {
    const lang = localStorage.getItem('lang') || 'en';
    if (typeof number == 'number') return number;
    else {
      // console.log(number.match(/^[0-9 ]{1,15}([,][0-9]{1,2})?$/))
      if (lang == 'en' && (number.match(this.quantityRegex) || []).length) {
        let commaCount = (number.match(/,/g) || []).length;
        if (commaCount === 0) return +number;
        else if (commaCount === 1) return number.replace(',', '');
        else if (commaCount > 1) {
          let newNumber = number;
          if (String.prototype.hasOwnProperty('replaceAll'))
            newNumber = number.replaceAll(',', '');
          else {
            for (let i = 0; i < commaCount; i++) {
              newNumber = newNumber.replace(',', '');
            }
          }
          return newNumber;
        }
      } else if (
        lang == 'fr' &&
        (number.match(this.quantityRegex_fr) || []).length
      ) {
        let modifiedNumber = number;
        if (number.includes(',')) modifiedNumber = number.replace(',', '.');
        let spaceCount = (number.match(/ /g) || []).length;
        if (spaceCount === 0) return +modifiedNumber;
        else if (spaceCount === 1) return modifiedNumber.replace(' ', '');
        else if (spaceCount > 1) {
          let newNumber = modifiedNumber;
          if (String.prototype.hasOwnProperty('replaceAll'))
            newNumber = modifiedNumber.replaceAll(' ', '');
          else {
            for (let i = 0; i < spaceCount; i++) {
              newNumber = newNumber.replace(' ', '');
            }
          }
          return newNumber;
        }
      } else return 0;
    }
  }

  convertToNumberSystem(number) {
    const lang = localStorage.getItem('lang') || 'en';
    if (lang == 'fr') return number.toString().replace('.', ',');
    // if (lang == 'fr') return number.toLocaleString('fr-FR');
    // else if (lang == 'en') return number.toLocaleString('en-EN');
    else return number;
  }

  // ----- USER -----
  editMember(id, entity) {
    let url = '/nodes/members/' + id + '/';
    return this.patchData(url, entity);
  }

  updateUserConfigurations(userConfig) {
    let config = JSON.parse(localStorage.getItem('tenant_configurations'));
    let updatedConfig = {
      ...config,
      ...userConfig,
    };
    // updatedConfig = { ...updatedConfig, can_create_purchase_order: false };
    // console.log(updatedConfig);
    localStorage.setItem(
      'tenant_configurations',
      JSON.stringify(updatedConfig)
    );
  }

  resendInvite(id) {
    let url = '/nodes/members:resend-invite';
    let baseUrl = this.$baseUrl + url;
    return this.http.post(baseUrl, { id });
  }

  getMemberDetails() {
    let id = JSON.parse(localStorage.getItem('ROUserData')).user_id;
    let url = `/accounts/users/${id}/`;
    return this.getData(url);
  }

  editUserDetails(data) {
    let id = JSON.parse(localStorage.getItem('ROUserData')).user_id;
    let url = `/accounts/users/${id}/`;
    return this.patchData(url, data);
  }

  // TENANT

  getTenantConfigurations() {
    let url = `/tenants/`;
    return this.getData(url);
  }

  getCountryData() {
    let params = { limit: 200, offset: 0 };
    let url = '/tenants/countries/';
    return this.getDataByParams(url, params) as Observable<
      ResponseArray<Country[]>
    >;
  }

  refreshToken(data) {
    let url = '/auth/token/refresh/';
    return this.postData(url, data);
  }

  verifyToken(data) {
    let url = '/token/verify/';
    return this.postData(url, data);
  }

  // DYNAMIC FORMS

  getDynamicFormKeys(id) {
    let url = `/dynamic-form/${id}/`;
    return this.getData(url);
  }

  updateDynamicFormField(id, data) {
    let url = `/dynamic-form/field/${id}/`;
    return this.patchData(url, data);
  }

  getSubmissionId(data) {
    let url = `/dynamic-form/submission/`;
    return this.postData(url, data);
  }

  submitFormFieldValues(id, data) {
    let url = `/dynamic-form/submission/${id}/`;
    return this.postData(url, data);
  }

  resentInvite(userId, nodeID) {
    let url = `/entities/${nodeID}/resend_invite/?user_id=${userId}&node_id=${nodeID}`;
    return this.getData(url);
  }

  updateEntity(id, data) {
    let url = `/nodes/${id}/`;
    return this.patchData(url, data);
  }

  getEntitiesList(params) {
    let url = '/supply-chain/connection/';
    return this.getDataByParams(url, params);
  }

  getEntityDetails(id) {
    let url = `/nodes/${id}/`;
    return this.getData(url);
  }

  getVillage(id) {
    let url = '/entities/' + id + '/villages/get/';
    return this.getData(url);
  }

  getFormFieldValues(id) {
    let url = `/dynamic-form/submission/${id}/`;
    return this.getData(url);
  }

  // ----- NODE -----

  getTenantConstants() {
    let url = '/constants/';
    return this.getData(url);
  }

  updateNodeDetails(id, data) {
    let url = `/nodes/${id}/`;
    return this.patchData(url, data);
  }

  getNodeList(params) {
    let url = '/supply-chain/connection/';
    return this.getDataByParams<ResponseArray<ConnectionNode[]>>(url, params);
  }

  searchNode(params) {
    let url = '/nodes/search/';
    return this.getDataByParams(url, params);
  }

  getNodeDetails(id) {
    let url = `/nodes/${id}/`;
    return this.getData(url);
  }

  getTeamMembers(params) {
    let url = '/nodes/members/';
    return this.getDataByParams(url, params);
  }

  // REPORT

  createReport(data) {
    let url = '/reports/create/';
    return this.postData(url, data);
  }

  getEntityReport(id_entity, id_member) {
    let url = '/reports/get/?user_id=' + id_member + '&node_id=' + id_entity;
    return this.getData(url);
  }

  downloadReport(data) {
    let url =
      '/reports/' +
      data.id +
      '/download/?user_id=' +
      data.id_member +
      '&node_id=' +
      data.id_entity;
    return this.getFile(url, data);
  }
  // ----- NOTIFICATIONS -----

  readNotification(data) {
    let url = '/notifications/read/';
    return this.patchData(url, data);
  }

  getNotifications(data) {
    let url = '/notifications/';
    return this.getDataByParams(url, data);
  }

  getEntityNotificationList() {
    let url = '/notifications/summary/';
    return this.getData(url);
  }

  // ----- PRODUCT -----

  getProducts(params) {
    params = { ...params, supply_chain: localStorage.getItem('supplyChainId') };
    let url = '/products/';
    return this.getDataByParams(url, params) as Observable<
      ResponseArray<Product[]>
    >;
  }

  getSupplyChainList(data) {
    let url = '/supply-chain/';
    return this.getDataByParams(url, data);
  }

  getBatches(params) {
    let url = '/products/batch/';
    return this.getDataByParams(url, params);
  }

  getStockDetails(id) {
    let url = '/products/batch/' + id + '/';
    return this.getData(url);
  }

  getExternalTransactionsList(params) {
    let url = '/transactions/external/';
    return this.getDataByParams(url, params);
  }

  getInternalTransactionsList(params) {
    let url = '/transactions/internal/';
    return this.getDataByParams(url, params);
  }

  getTransactionDetails(id, type) {
    let url;
    if (type == 1) {
      url = '/transactions/external/' + id + '/';
    } else {
      url = '/transactions/internal/' + id + '/';
    }
    return this.getData(url);
  }

  getClaims(params?): any {
    const url = '/claims/';
    if (params) return this.getDataByParams(url, params);
    else return this.getData(url);
  }

  attachClaim(data) {
    let url = '/claims/attach/';
    return this.postData(url, data);
  }

  attachClaimFiles(params, data) {
    let url = '/claims/file/create/';
    return this.postFileWithParams(url, data, params);
  }

  deleteClaimFile(id) {
    let url = '/claims/file/' + id + '/delete/';
    return this.postData(url, '');
  }

  getBulkUploadTemplate(data) {
    let url = '/bulk/template/';
    return this.getFile(url, data);
  }

  downloadFile(file, name) {
    fetch(file, { method: 'GET' })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        saveAs(blob, name);
      })
      .catch((err) => {
        console.error('err: ', err);
      });
  }

  verifyBulkUploadTemplate(file) {
    let url = '/bulk/uploads/';
    return this.postFileWithProgress(url, file);
  }

  reVerifyBulkUploadTemplate(id, data) {
    let url = `/bulk/uploads/${id}/`;
    return this.patchData(url, data);
  }

  initiateBulkUpload(id, data) {
    let url = `/bulk/uploads/${id}/:initiate`;
    return this.postData(url, data);
  }

  setTableFilters(type, data) {
    if (type == 101) {
      localStorage.setItem('connectionFilters', JSON.stringify(data));
    } else if (type == 111) {
      localStorage.setItem('beekeeperFilters', JSON.stringify(data));
    } else if (type == 201) {
      localStorage.setItem('transactionFilters', JSON.stringify(data));
    } else if (type == 211) {
      localStorage.setItem('transactionProcessing', JSON.stringify(data));
    } else if (type == 221) {
      localStorage.setItem('transactionSend', JSON.stringify(data));
    } else if (type == 301) {
      localStorage.setItem('stockFilters', JSON.stringify(data));
    } else if (type == 311) {
      localStorage.setItem('stockFinalProductFilters', JSON.stringify(data));
    } else if (type == 321) {
      localStorage.setItem('stockLossFilters', JSON.stringify(data));
    } else if (type == 401) {
      localStorage.setItem('ordersFilters', JSON.stringify(data));
    } else if (type == 501) {
      localStorage.setItem('verificationFilters', JSON.stringify(data));
    } else if (type == 601) {
      localStorage.setItem('bulkUploadFilters', JSON.stringify(data));
    } else if (type == 701) {
      localStorage.setItem('contractsFilters', JSON.stringify(data));
    } else if (type == 801) {
      localStorage.setItem('dashboardFilters', JSON.stringify(data));
    } else if (type == 901) {
      localStorage.setItem('dashboardYearFilters', JSON.stringify(data));
    }
  }

  getTableFilters(type) {
    if (type == 101) {
      return JSON.parse(localStorage.getItem('connectionFilters'));
    } else if (type == 111) {
      return JSON.parse(localStorage.getItem('beekeeperFilters'));
    } else if (type == 201) {
      return JSON.parse(localStorage.getItem('transactionFilters'));
    } else if (type == 211) {
      return JSON.parse(localStorage.getItem('transactionProcessing'));
    } else if (type == 221) {
      return JSON.parse(localStorage.getItem('transactionSend'));
    } else if (type == 301) {
      return JSON.parse(localStorage.getItem('stockFilters'));
    } else if (type == 311) {
      return JSON.parse(localStorage.getItem('stockFinalProductFilters'));
    } else if (type == 321) {
      return JSON.parse(localStorage.getItem('stockLossFilters'));
    } else if (type == 401) {
      return JSON.parse(localStorage.getItem('ordersFilters'));
    } else if (type == 501) {
      return JSON.parse(localStorage.getItem('verificationFilters'));
    } else if (type == 601) {
      return JSON.parse(localStorage.getItem('bulkUploadFilters'));
    } else if (type == 701) {
      return JSON.parse(localStorage.getItem('contractsFilters'));
    } else if (type == 801) {
      return JSON.parse(localStorage.getItem('dashboardFilters'));
    } else if (type == 901) {
      return JSON.parse(localStorage.getItem('dashboardYearFilters'));
    }
  }

  clearTableFilters() {
    localStorage.removeItem('connectionFilters');
    localStorage.removeItem('beekeeperFilters');
    localStorage.removeItem('transactionFilters');
    localStorage.removeItem('transactionProcessing');
    localStorage.removeItem('transactionSend');
    localStorage.removeItem('stockFilters');
    localStorage.removeItem('stockFinalProductFilters');
    localStorage.removeItem('stockLossFilters');
    localStorage.removeItem('ordersFilters');
    localStorage.removeItem('verificationFilters');
    localStorage.removeItem('bulkUploadFilters');
    localStorage.removeItem('contractsFilters');
    localStorage.removeItem('dashboardFilters');
    localStorage.removeItem('dashboardYearFilters');
  }

  // primary methods
  getData<TData>(url: any): Observable<TData> {
    let baseUrl = this.$baseUrl + url;
    return this.http.get<TData>(baseUrl);
  }

  getDataByParams<TData>(url: string, params): Observable<TData> {
    let baseUrl = this.$baseUrl + url;
    return this.http.get<TData>(baseUrl, this.options2(params));
  }

  getFile(url: any, params) {
    let baseUrl = this.$baseUrl + url;
    return this.http.get(baseUrl, this.options3(params));
  }

  postData<TData>(url: any, data: any): Observable<TData> {
    let baseUrl = this.$baseUrl + url;
    return this.http.post<TData>(baseUrl, data);
  }

  postFormData<TData>(url: any, data: any): Observable<TData> {
    let baseUrl = this.$baseUrl + url;
    return this.http.post<TData>(baseUrl, data);
  }

  patchData<TData>(url: any, data: any): Observable<TData> {
    let baseUrl = this.$baseUrl + url;
    return this.http.patch<TData>(baseUrl, data);
  }

  postFile<TData>(url: any, data: any): Observable<TData> {
    let baseUrl = this.$baseUrl + url;
    return this.http.post<TData>(baseUrl, data);
  }

  postFileWithParams<TData>(
    url: any,
    data: any,
    params: any
  ): Observable<TData> {
    let baseUrl = this.$baseUrl + url;
    return this.http.post<TData>(baseUrl, data, this.options2(params));
  }

  postFileWithProgress(url: any, data: any) {
    let baseUrl = this.$baseUrl + url;
    return this.http.post(baseUrl, data, {
      reportProgress: true,
      observe: 'events',
    });
  }

  deleteData<TData>(url: any): Observable<TData> {
    let baseUrl = this.$baseUrl + url;
    return this.http.delete<TData>(baseUrl);
  }

  // httpOptions

  options2(param) {
    const httpOptions = {
      params: new HttpParams({ fromObject: param }),
    };
    return httpOptions;
  }

  options3(param): any {
    const httpOptions = {
      params: new HttpParams({ fromObject: param }),
      responseType: 'arraybuffer' as 'json',
      'observe?': 'response',
    };
    return httpOptions;
  }
}
