import { IsLoggedIn } from '../../../authentication/services/guard/isLoggedIn';

export const authenticationRoutes = [
  {
    path: 'login',
    loadChildren: () =>
      import('../../../authentication/login/login.module').then(
        (m) => m.LoginModule
      ),
    data: { showComponents: false },
    canLoad: [IsLoggedIn],
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import(
        '../../../authentication/invite-connection/invite-connection.module'
      ).then((m) => m.InviteConnectionModule),
  },
  {
    path: 'invite-member',
    loadChildren: () =>
      import('../../../authentication/invite-member/invite-member.module').then(
        (m) => m.InviteMemberModule
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import(
        '../../../authentication/forgot-password/forgot-password.module'
      ).then((m) => m.ForgotPasswordModule),
  },
  {
    path: 'reset',
    loadChildren: () =>
      import(
        '../../../authentication/reset-password/reset-password.module'
      ).then((m) => m.ResetPasswordModule),
  },
  {
    path: 'set-password',
    loadChildren: () =>
      import('../../../authentication/set-password/set-password.module').then(
        (m) => m.SetPasswordModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import(
        '../../../authentication/privacy-policy/privacy-policy.module'
      ).then((m) => m.PrivacyPolicyModule),
  },
  {
    path: 'beekeeper-charter',
    loadChildren: () =>
      import(
        '../../../authentication/beekeeper-charter/beekeeper-charter.module'
      ).then((m) => m.BeekeeperCharterModule),
  },
];
